/*!

=========================================================
* Argon Dashboard React - v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  Media,
} from "reactstrap";
import { data } from "jquery";
import QuoteCard from "../../components/Cards/Quote.component.js";

const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className=" align-items-center align-content-center mb-4 row-cols-xs-1 row-cols-sm-1 row-cols-lg-5 row-cols-sm-2 row-cols-md-3 justify-content-md-center">
              <QuoteCard quoteIndex={1} />
              <QuoteCard quoteIndex={2} />
              <QuoteCard quoteIndex={3} />
              <QuoteCard quoteIndex={4} />
              <QuoteCard quoteIndex={5} />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
