import React, { Component, useEffect } from "react";
import FirebaseDataService from "../../services/firebase.service.js";
import { getDatabase, ref, onChildChanged, onValue } from "firebase/database";
import toast, { Toaster } from "react-hot-toast";
import Moment, { duration } from "moment";
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
  Media,
} from "reactstrap";
export default class Quote extends Component {
  constructor(props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveQuote = this.setActiveQuote.bind(this);
    this.onDataChange = this.onDataChange.bind(this);

    this.state = {
      currentQuote: null,
      currentIndex: this.props.quoteIndex,
    };
  }

  componentDidMount() {
    //    FirebaseDataService.getAll().onValue("currencies/", this.onDataChange);
    onValue(
      FirebaseDataService.getChild(this.state.currentIndex),
      (snapshot) => {
        this.onDataChange(snapshot.val());
      }
    );
    onChildChanged(
      FirebaseDataService.getChild(this.state.currentIndex),
      (snapshot) => {
        this.onDataChange(snapshot.val());
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 pl-3 pr-3 pt-2 pb-0">
              <div className="flex ">
                <Row className="align-items-center">
                  <div className="col flex-1">
                    <img
                      className="avatar rounded-circle mr-1"
                      src={require("../../assets/img/theme/" +
                        this.state.currentQuote.flag +
                        ".png")}
                      alt=""
                    />
                  </div>
                  <div className="col flex-1">
                    <p tag="h1" className="text-nowrap font-medium mb-0 pr-0">
                      {this.state.currentQuote && (
                        <div>
                          Cambio de cotización en {this.state.currentQuote.name}
                        </div>
                      )}
                    </p>
                  </div>
                </Row>
                <Row className=" align-items-center pr-3">
                  <p className=" text-sm text-gray-500 w-100 text-right">
                    {this.state.currentQuote && (
                      <div>
                        {Moment(this.state.currentQuote.update).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </div>
                    )}
                  </p>
                </Row>
              </div>
            </div>
            <div className="flex ">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-100 border border-transparent bg-orange text-white rounded-bottom p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Cerrar
              </button>
            </div>
          </div>
        )),
          {
            duration: Infinity,
          };
      }
    );
    this.setState({
      currentIndex: this.props.quoteIndex,
    });
  }

  componentWillUnmount() {
    // FirebaseDataService.getAll().off("currencies/", this.onDataChange);
  }

  setActiveQuote(quote, index) {
    this.setState({
      currentQuote: quote,
      currentIndex: index,
    });
  }

  onDataChange(item) {
    var date = new Date(item.update);
    let data = {
      name: item.name,
      flag: item.flag,
      update: new Date(item.update),
      buy: item.buy,
      sell: item.sell,
      difBuy: item.difBuy,
      difSell: item.difSell,
    };
    this.setState({
      currentQuote: data,
    });
  }

  refreshList() {
    this.setState({
      currentQuote: null,
    });
  }

  render() {
    const { currentQuote, currentIndex } = this.state;
    return (
      <Col className="mb-3 align-self-stretch col-xs-1 col-sm-6 col-md-4 col-lg">
        <Card className="bg-secondary shadow-card mb-0 mb-xl-0 h-100 w-100">
          <CardHeader>
            <Row className="align-items-center">
              <Col className="col-4">
                {currentQuote && (
                  <img
                    className="avatar bg-white rounded-circle"
                    alt="..."
                    src={require("../../assets/img/theme/" +
                      currentQuote.flag +
                      ".png")}
                  />
                )}
              </Col>
              <Col className="text-nowrap col-8 h1">
                {currentQuote && <div>{currentQuote.name}</div>}
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0">
            <Row className=" ml-0 mr-0">
              <div className="col pl-0 pr-0">
                <div className="col text-center">
                  <span className="h2 font-weight-bold mb-0">Compra</span>
                </div>
                {currentQuote && (
                  <div className="row align-items-baseline">
                    <div className="col-6 h2 font-weight-bold text-right">
                      {currentQuote.buy}
                    </div>
                    <div className="col-6 text-left">
                      {currentQuote && currentQuote.difBuy >= 0 && (
                        <div className="text-success text-left">
                          <div className=" fa fa-arrow-up font-weight-bold"></div>
                          &nbsp;{"$ "} {currentQuote.difBuy}
                        </div>
                      )}
                      {currentQuote && currentQuote.difBuy < 0 && (
                        <div className="text-red text-left">
                          <div className=" fa fa-arrow-up font-weight-bold"></div>
                          &nbsp;{"$ "} {currentQuote.difBuy}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <hr className="my-2"></hr>
                <div className="col text-center">
                  <span className="h2 font-weight-bold mb-0">Venta</span>
                </div>
                {currentQuote && (
                  <div className="row align-items-baseline">
                    <div className="col-6 h2 font-weight-bold text-right">
                      {currentQuote.sell}
                    </div>
                    <div className="col-6 text-left">
                      {currentQuote && currentQuote.difSell >= 0 && (
                        <div className="text-success text-left">
                          <div className=" fa fa-arrow-up font-weight-bold"></div>
                          &nbsp;{"$ "} {currentQuote.difSell}
                        </div>
                      )}
                      {currentQuote && currentQuote.difSell < 0 && (
                        <div className="text-red text-left">
                          <div className=" fa fa-arrow-up font-weight-bold"></div>
                          &nbsp;{"$ "} {currentQuote.difSell}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col text-center">
                  <span className="h5 text-orange ">
                    {currentQuote && (
                      <div>
                        {Moment(currentQuote.update).format("DD/MM/YYYY HH:mm")}
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </Row>
            {/* <Row className="mt-2 mb-2">
              <Col className="text-center">
                <Button
                  className=""
                  color="info"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="sm"
                >
                  Ver gráfica
                </Button>
              </Col>
            </Row> */}
          </CardBody>
        </Card>
      </Col>
    );
  }
}
