import { push, child, ref, get, onValue, off } from "firebase/database";
import database from "../firebase";

const db = ref(database, "/currencies")

class FirebaseDataService {

  getDatabase() {
    return database
  }

  getAll() {
  //   var quotes = []
  //  return get(child(db, `currencies/`)).then((snapshot) => {
  //     if (snapshot.exists()) {
  //       quotes = snapshot.val();
  //       console.log(quotes);
  //       return quotes;
  //     } else {
  //       console.log("No data available");
  //       return quotes;
  //     }
  //   }).catch((error) => {
  //     console.error(error);
  //     return quotes;
  //   });

  //   return quotes;
  return db
  }

  getChild(id){
    return ref(database, "/currencies/"+id)
  }

  create(tutorial) {
    return db.push(tutorial);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(key) {
    return db.child(key).remove();
  }

  deleteAll() {
    return db.remove();
  }
}

export default new FirebaseDataService();
