import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCWI3LxIgSu2kjhmXrVU0Lj5D6-SP6F4FA",
  authDomain: "pizarraapp.firebaseapp.com",
  databaseURL: "https://pizarraapp.firebaseio.com",
  projectId: "pizarraapp",
  storageBucket: "pizarraapp.appspot.com",
  messagingSenderId: "42491970528",
  appId: "1:42491970528:web:92a953457b7bec4392e185",
  measurementId: "G-83SR7480ZK"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export default database;
